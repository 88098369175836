import { useInfiniteQuery, useQueryClient } from "@tanstack/vue-query";

import type { GqlErrorHandlingOptions } from "../useGqlErrorHandling.js";

type Options = {
  itemsPerPage?: number;
  defaultSearchTerm: string;
  errorHandling?: GqlErrorHandlingOptions;
};

export default function useClaimFiltering(options?: Options) {
  const route = useRoute();

  const itemsPerPage = options?.itemsPerPage || 10;
  const _searchTerm = ref(options?.defaultSearchTerm || "");
  const queryClient = useQueryClient();
  const statusFilter = useSubmissionStatusUrlMap();
  const filterObject = useClaimFilterObject();

  async function queryFn({ pageParam = 0 }: { pageParam?: number }) {
    const res = await GqlFilterClaims({
      limit: itemsPerPage + 1, // fetch one more item to check if there are more items to fetch
      offset: pageParam,
      search: `%${_searchTerm.value}%`,
      claimStatus: statusFilter.value,
      filter: filterObject.value
    });
    // remove the last item if there are more items to fetch
    const hasMoreItems = res.data.length > itemsPerPage;
    const data = hasMoreItems ? res.data.slice(0, res.data.length - 1) : res.data;
    const nextCursor = hasMoreItems ? pageParam + itemsPerPage : undefined;
    return {
      data,
      nextCursor
    };
  }
  watch(filterObject, () => {
    refetch();
  });

  const {
    data,
    error,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
    isError,
    refetch,
    hasNextPage
  } = useInfiniteQuery({
    queryKey: [QUERY_KEYS.claims, route.params.filter, filterObject.value],
    queryFn: queryFn,
    getNextPageParam: (lastPage) => lastPage && lastPage.nextCursor,
    initialPageParam: 0
  });

  function onSearchTermChanged(term: string) {
    _searchTerm.value = term;
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.claims, route.params.filter] });
  }

  const claims = computed(() => {
    return (
      data.value?.pages.flatMap((page) =>
        page.data.map((claim) => ({
          ...claim,
          categories: normalizeCategories(claim.claimCategories)
        }))
      ) || []
    );
  });
  useGqlErrorHandling(error, data, options?.errorHandling);

  return {
    claims,
    isFetching,
    isFetchingNextPage,
    isLoading,
    isError,
    error,
    hasNextPage,
    fetchNextPage: () => {
      // safe guard to prevent weired behavior
      if (hasNextPage) fetchNextPage();
    },
    onSearchTermChanged,
    searchTerm: readonly(_searchTerm)
  };
}
