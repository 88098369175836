<script lang="ts" setup>
import type { Category } from "~/types";

const { categories } = defineProps<{
  categories: Category[];
  class?: string;
  badgeClass?: string;
}>();

const { locale } = useI18n();
const label = computed(() => (locale.value === "de" ? "labelDe" : "labelEn"));
const categoriesSorted = computed(() =>
  categories
    .map((category) => ({ name: category.name, label: category[label.value] }))
    .sort((a, b) => a.label.localeCompare(b.label))
);
</script>

<template>
  <ul v-if="categories.length" class="grow flex flex-wrap gap-1">
    <li v-for="category in categoriesSorted" :key="category.name" class="flex">
      <BasicBadge class="tracking-wide" variant="square" :class="$props.badgeClass">
        {{ category.label }}
      </BasicBadge>
    </li>
  </ul>
</template>
