function useScopeFilter() {
  const store = useFilteringStore();
  return computed(() => (store.scope !== "all" ? { internal: { _eq: store.scope === "editorial" } } : {}));
}

function useGetClaimStatusFilters() {
  const store = useFilteringStore();

  const isMarked = {
    userClaimStatuses: {
      hasMarked: { _eq: true }
    }
  };
  const isNew = {
    _or: [{ userClaimStatuses: { hasRead: { _eq: false } } }, { _not: { userClaimStatuses: {} } }]
  };

  return computed(() => {
    const filters = [...(store.getIsMarked() ? [isMarked] : []), ...(store.getIsNew() ? [isNew] : [])];
    return filters.length > 0 ? { _or: filters } : {};
  });
}

function useCategoryFilters() {
  const store = useFilteringStore();

  return computed(() =>
    store.categories.length > 0
      ? {
          claimCategories: { category: { name: { _in: store.categories.map((category) => category.name) } } }
        }
      : {}
  );
}

export default function useClaimFilterObject() {
  const scopeFilter = useScopeFilter();
  const claimStatusFilters = useGetClaimStatusFilters();
  const categoryFilters = useCategoryFilters();
  return computed(() => ({
    _and: [scopeFilter.value, claimStatusFilters.value, categoryFilters.value]
  }));
}
